import classNames from '@/util/classname';
import { ExternalLinkIcon } from '@heroicons/react/solid';
import { IconProps } from './icons';
import LinkWrapper from './LinkWrapper';

export default function ServiceCard({
    href,
    bgStyles,
    text,
    linkStyles,
    icon,
    newTab,
}: {
    href: string;
    bgStyles: string;
    text: string;
    linkStyles?: string;
    icon?: {
        Icon: (props: IconProps) => JSX.Element;
        bg: string;
    };
    newTab?: boolean;
}) {
    const external = href.charAt(0) !== '/';

    return (
        <LinkWrapper href={href} className={linkStyles} newTab={newTab}>
            <div
                className={classNames(
                    bgStyles,
                    'bg-center bg-cover rounded-lg'
                )}
            >
                <div
                    className={classNames(
                        'rounded-lg relative',
                        icon?.Icon ? '' : 'bg-gradient-to-b from-black/30'
                    )}
                >
                    <h3 className="p-4 text-2xl font-bold">{text}</h3>
                    {external && (
                        <span className="absolute bg-white rounded-full -right-2 -top-2">
                            <div className="p-2 text-gray-800">
                                <ExternalLinkIcon className="w-6 h-6" />
                            </div>
                        </span>
                    )}

                    {icon && (
                        <div className="w-3/5 pt-2 pb-8 m-auto">
                            <div
                                className={classNames(
                                    'transition-shadow w-full aspect-[1/1] rounded-2xl sm:rounded-3xl shadow-2xl group-hover:shadow-lg flex items-center justify-center text-black',
                                    icon.bg
                                )}
                            >
                                <icon.Icon className="w-9/12" colored={true} />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </LinkWrapper>
    );
}
